import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TableRenew from "../../components/Tables/TableRenew";
import SubscriptionsAPI from "../../services/API/clients/SubscriptionsAPI";
import { HeadersRenew } from "./HeaderRenew";

export default function Renew() {
    const { t } = useTranslation();
    const [subscriptions, setSubscriptions] = useState<
        {
            pagination: {
                current: number;
                last: number;
                totalItems: number;
                parPage: number;
            };
            data: {
                renewedSubscriptions: [],
                failedRenewals: [],
                createdAt: string
            }[]
        }
    >({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0,
        },
        data: [],
    });

    const getList = async () => {
        const response = await SubscriptionsAPI.renewSubscriptions();
        if (response.status === 200) {
            setSubscriptions(response.body);
        }
    };

    useEffect(() => {
        getList();
    }, []);

    const callRenews = async (page: number) => {
        const response = await SubscriptionsAPI.renewSubscriptionsPagination(page, "");
        if (response.status === 200) {
            setSubscriptions(response.body);
        }
    };

    return (
        <div>
            {subscriptions?.pagination.totalItems > 0 ? (
                <div className="w-full max-w-7xl mx-auto flex flex-col gap-4">
                    <TableRenew header={HeadersRenew} data={subscriptions?.data} itemsPerPage={subscriptions?.pagination} callPictures={callRenews} />
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                    <FontAwesomeIcon icon="images" className="mt-4 text-4xl text-gray-500/50 mb-4" />
                    <Typography placeholder="" className="text-base text-gray-700 mb-4">
                        {t("noSubs")}
                    </Typography>
                </div>
            )}
        </div>
    );
}
