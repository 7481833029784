import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import { Card, CardBody, Typography } from "@material-tailwind/react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ModalWrapper from "./ModalWrapper";

export default function ModalListRenews({
    open,
    setOpen,
    datas
}: Readonly<{
    open: boolean;
    setOpen: () => void;
    datas: {
        id: string;
        owner: string;
        plan: string;
        status: string;
        period: string;
        createdAt: string;
        giftDuration?: number;
    }[]
}>) {
    const { t } = useTranslation();
    
    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center">
                    {t("listSubscription")}
                </Dialog.Title>
                <FontAwesomeIcon
                    icon="x"
                    onClick={() => {
                        return "cancel";
                    }}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer transition-all"
                />


                <div className=" max-h-[360px] mt-4 overflow-y-auto">
                    {datas.length > 0 ? (
                        <div className="flex flex-col gap-2">
                            {datas.map((data) => (
                                <Card placeholder="" key={data.createdAt} className="border">
                                    <CardBody placeholder="">
                                        <Typography placeholder="" variant="h5" color="blue-gray" className="mb-2">
                                            <a href={`/subscription/${data.id}`}>{data.id}</a>{" "}
                                        </Typography>
                                        <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">
                                            {moment(data.createdAt).format("DD.MM.YYYY HH:mm:ss")}
                                        </Typography>
                                        <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">
                                            {t(data.status)} - {t(data.period)} - <b>{t(data.plan)}</b>
                                        </Typography>
                                    </CardBody>
                                </Card>
                            ))}
                        </div>
                    ) : (
                        <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                            <FontAwesomeIcon icon="shopping-cart" className="mt-4 text-4xl text-gray-500/50 mb-4" />
                            <Typography placeholder="" className="text-base text-gray-700 mb-4">
                                {t("noSubs")}
                            </Typography>
                        </div>
                    )}
                </div>

                <div className="flex justify-center gap-4 mt-4">
                    <button
                        type="button"
                        className="rounded-full text-white bg-indian-500 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => setOpen()}
                    >
                        {t("close")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
