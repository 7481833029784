export const HeaderValidation = [
    {
        id: "picture",
        col: "minPath"
    },
    {
        id: "id",
        col: "id"
    },
    {
        id: "reviewer",
        col: "reviewer"
    },
    {
        id: "reason",
        col: "reason"
    },
    {
        id: "action",
        col: "action"
    },
    {
        id: "reviewedAt",
        col: "reviewedAt"
    }
]