import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Profil from "./composants/Profil";

import { IconButton, Popover, PopoverContent, PopoverHandler } from "@material-tailwind/react";

export default function Header() {
    const { t } = useTranslation();

    return (
        <nav className="block w-full sticky top-0 left-0 z-40 -mt-2.5">
            <div className="overflow-visible bg-white pt-0.5 md:pt-0 shadow-md shadow-var">
                <div
                    className={`md:my-2.5 mx-2 md:mx-4 relative flex flex-col md:flex-row`}
                >
                    <div className="my-1.5 md:my-0 flex flex-around flex-row w-full md:w-11/12 gap-1 md:gap-4">
                        <a href="/admin" className="flex items-center ml-1 w-full" style={{ maxWidth: "200px" }}>
                            <div className="w-full">
                            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 186 33.8">
                            <g>
                                <path fill="#F49D07" d="M30.8,14.1c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9
                                    C33.7,15.4,32.4,14.1,30.8,14.1z"/>
                                <path fill="#F49D07" d="M29.7,23.2c-0.6-0.5-1.4-0.7-2.1-0.6c-0.8,0.1-1.4,0.5-1.9,1.1c-0.5,0.7-1.1,1.3-1.7,1.8c-2,1.6-4.4,2.5-7,2.6
                                    c-5.9,0.1-11.1-4.9-11.3-10.8c-0.1-3,1-5.9,3.1-8.1c2.1-2.2,5-3.4,8-3.4c2.4,0,4.8,0.8,6.8,2.3l-8.5,6.6c-0.6,0.5-1,1.1-1.1,1.9
                                    c-0.1,0.8,0.1,1.5,0.6,2.1c0.5,0.6,1.1,1,1.9,1.1c0.8,0.1,1.5-0.1,2.1-0.6l11.1-8.6c0.6-0.5,1-1.1,1.1-1.9c0.1-0.8-0.1-1.5-0.6-2.1
                                    c-0.5-0.7-1.1-1.3-1.7-1.9c-3.7-3.6-8.8-5.2-14-4.5C7,1.3,1,7.5,0.1,15c-0.5,4.9,0.9,9.5,4.2,13.1c3.2,3.6,7.8,5.6,12.6,5.6
                                    c4.3,0,8.4-1.7,11.6-4.7c0.6-0.6,1.2-1.2,1.7-1.9c0.5-0.6,0.7-1.4,0.6-2.1C30.7,24.3,30.3,23.6,29.7,23.2z"/>
                                <path fill="#585756" d="M83,0c-9.3,0-16.9,7.6-16.9,16.9S73.7,33.7,83,33.7c9.3,0,16.9-7.6,16.9-16.9S92.3,0,83,0z M94.2,16.9
                                    C94.2,23,89.2,28,83,28c-6.1,0-11.1-5-11.1-11.1S76.9,5.7,83,5.7C89.2,5.7,94.2,10.7,94.2,16.9z"/>
                                <path fill="#585756" d="M119.1,0c-9.3,0-16.9,7.6-16.9,16.9s7.6,16.9,16.9,16.9c9.3,0,16.9-7.6,16.9-16.9S128.4,0,119.1,0z
                                    M130.2,16.9c0,6.1-5,11.1-11.1,11.1C113,28,108,23,108,16.9s5-11.1,11.1-11.1C125.2,5.7,130.2,10.7,130.2,16.9z"/>
                                <path fill="#585756" d="M63,27.1H43.8L65.1,5.7c0.5-0.5,0.7-1.2,0.7-1.9C65.8,2.3,64.5,1,63,1H36.8C35.3,1,34,2.3,34,3.8
                                    c0,1.6,1.3,2.9,2.9,2.9H56L34.8,27.9c-0.1,0.1-0.3,0.3-0.4,0.5c-0.3,0.5-0.5,1-0.5,1.6c0,0.6,0.2,1.1,0.5,1.6
                                    c0.5,0.8,1.4,1.3,2.4,1.3H63c1.6,0,2.9-1.3,2.9-2.9C65.8,28.4,64.5,27.1,63,27.1z"/>
                                <path fill="#585756" d="M186,13.1C186,5.9,180.1,0,172.9,0c-4.1,0-7.7,1.9-10.1,4.8c-2.4-2.9-6.1-4.8-10.1-4.8
                                    c-7.2,0-13.1,5.9-13.1,13.1h0v17.8c0,1.6,1.3,2.9,2.9,2.9c1.6,0,3-1.3,3-2.9l0-17.8h0c0-4,3.2-7.2,7.2-7.2c4,0,7.2,3.2,7.2,7.2
                                    c0,0,0,0,0,0h0l0,17.8c0,1.6,1.3,2.9,2.9,2.9c1.6,0,3-1.3,3-2.9V13.1c0,0,0,0,0,0c0-4,3.2-7.2,7.2-7.2c4,0,7.2,3.2,7.2,7.2h0
                                    l0,17.8c0,1.6,1.3,2.9,2.9,2.9c1.6,0,3-1.3,3-2.9L186,13.1L186,13.1z"/>
                            </g>
                            </svg>
                            </div>
                        </a>
                        <div className="flex m-auto relative w-full" style={{ lineHeight: 0 }}>
                            <div className="w-full">
                            </div>
                        </div>
                        <Popover>
                            <PopoverHandler>
                                <IconButton placeholder="" color="orange" className="md:hidden px-2 mr-1 w-16">
                                    <FontAwesomeIcon icon="bars" className="text-white" />
                                </IconButton>
                            </PopoverHandler>
                            <PopoverContent
                                placeholder=""
                                className={`z-40 flex-col px-4 py-2 w-full max-h-[calc(100vh-50px)] overflow-y-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-comfortaa font-thin`}
                            >
                                <div className="py-1">
                                    <a href="/newsletter">
                                        <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                            <FontAwesomeIcon icon="envelope" className="mr-2" />
                                            {t("newsletter")}
                                        </button>
                                    </a>
                                    <button
                                        className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg"
                                        onClick={() => {
                                            localStorage.removeItem("token");
                                            window.location.href = "/";
                                        }}
                                    >
                                        <FontAwesomeIcon icon="right-from-bracket" className="mr-2" />
                                        {t("disconnect")}
                                    </button>
                                </div>
                            </PopoverContent>
                        </Popover>
                    </div>
                    <div className="hidden md:flex justify-around items-center gap-2 text-left mx-0 w-screen md:mt-3 sm:w-auto">
                        <Profil />
                    </div>
                </div>
            </div>
        </nav>
    );
}
