import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../components/NavBar/Header";
import { useTranslation } from "react-i18next";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import TitlePage from "../../components/TitlePage";
import GradientButton from "../../components/Form/Button/GradientButton";
import React, { useEffect } from "react";
import NewsletterAPI from "../../services/API/clients/NewsletterAPI";
import { Headers } from "./Header";
import Table from "../../components/Tables/Table";
import exportFromJSON from "export-from-json";
import { toast } from "react-toastify";

export default function Newsletter() {
    const { t } = useTranslation();
    const [list, setList] = React.useState<any[]>([]);
    document.title = `ezoom | ${t("newsletter")}`;

    const getList = async () => {
        const response = await NewsletterAPI.news();

        setList(response.body);
    }

    useEffect(() => {
        getList()
    }, [])

    const exportMyFile = () => {
        const fileName = 'list-newsletter'
        const exportType = 'csv'
        exportFromJSON({ data: list, fileName, exportType })
    }

    const deleteMyUser = async (id: string) => {
        const response =  await NewsletterAPI.unSubscribe(id)
        if (response.status === 200 || response.status === 204) {
            getList()
            toast.success(t("successDelete"))
        }
        getList()
    }

    return (
        <div className="min-h-screen bg-gray-50 relative">
            <Header />
            <div className="flex flex-col h-full bg-gray-50 -mt-4 pt-4">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-12 bg-white z-30 border-b border-gray-200">
                    <TitlePage><FontAwesomeIcon icon="envelope" className="text-orange-500" /> {t("newsletter")}</TitlePage>
                </div>
                <WrapperGrayZone pt="4">
                    <div className="w-full max-w-7xl mx-auto md:px-8 flex flex-col gap-4">
                        <div className="w-fit ml-2 md:ml-0">
                            <GradientButton text="getCSV" onChanging={exportMyFile}  />
                        </div>
                        <div className="w-full overflow-x-auto">
                            <Table header={Headers} data={list} itemsPerPage={10} onClickAction={deleteMyUser} />
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
        </div>
    );
}
