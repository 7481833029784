import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppSettingsAPI from "../../../services/API/clients/AppSettingsAPI";
import { Button, Chip, Input, Option, Select, Typography } from "@material-tailwind/react";
import CategoriesAPI from "../../../services/API/clients/CategoriesAPI";
import TypesAPI from "../../../services/API/clients/TypesAPI";
import { toast } from "react-toastify";

export default function Restrictions({
    restrictKeywords,
    setRestrictKeywords,
    fetchData,
}: Readonly<{ restrictKeywords: { categories: string[]; typeStyles: string[] }; setRestrictKeywords: Function; fetchData: () => Promise<void> }>) {
    const { t } = useTranslation();
    const [allCategories, setAllCategories] = useState<{ enumValue: string; frLabel: string; enLabel: string }[]>([]);
    const [allTypeStyles, setAllTypeStyles] = useState<{ enumValue: string; frLabel: string; enLabel: string }[]>([]);
    const [categories, setCategories] = useState<string[]>(restrictKeywords.categories);
    const [typeStyles, setTypeStyles] = useState<string[]>(restrictKeywords.typeStyles);

    useEffect(() => {
        setCategories(restrictKeywords.categories);
        setTypeStyles(restrictKeywords.typeStyles);
    }, [restrictKeywords]);

    useEffect(() => {
        const fetchDatas = async () => {
            const response = await CategoriesAPI.getAll();
            if (response.status === 200) {
                setAllCategories(response.body);
            }
            const resp = await TypesAPI.getAll();
            if (resp.status === 200) {
                setAllTypeStyles(resp.body);
            }
        };
        fetchDatas();
    }, []);

    const handleSave = async () => {
        const datas = {
            value: {
                categories: categories.filter((category, index) => categories.indexOf(category) === index && category !== ""),
                typeStyles: typeStyles.filter((typeStyle, index) => typeStyles.indexOf(typeStyle) === index && typeStyle !== ""),
            }
        }

        const response = await AppSettingsAPI.modifAppSetting("RESTRICT_KEYWORDS", datas);
        if (response.status === 200) {
            toast.success(t("RESTRICT_KEYWORDS_updated"));
            await fetchData();
        } else {
            toast.error(t("RESTRICT_KEYWORDS_error"));
        }
    };

    return (
        <div>
            <Typography placeholder="" variant="h5">
                {t("RESTRICT_KEYWORDS")}
            </Typography>
            <Typography placeholder="" variant="small">
                {t("RESTRICT_KEYWORDS_d")}
            </Typography>
            <div>
                <Typography placeholder="" variant="h6" className="mt-2">
                    {t("CATEGORIES")}
                </Typography>
                <Select placeholder="" variant="static">
                    {allCategories?.length > 0 ? (
                        allCategories?.map((category: any) => (
                            <Option
                                key={category.id}
                                value={category.id}
                                onClick={() => {
                                    setCategories([...categories, category.enumValue]);
                                }}
                            >
                                {category.frLabel}
                            </Option>
                        ))
                    ) : (
                        <Option value="">{t("NO_DATA")}</Option>
                    )}
                </Select>
                <div className="flex gap-2 mt-2 mb-4 flex-wrap">
                    {categories.map((category, index) => (
                        <Chip
                            key={category}
                            color="orange"
                            size="lg"
                            value={category}
                            onClose={() => {
                                const newCategories = [...categories];
                                newCategories.splice(index, 1);
                                setCategories(newCategories);
                            }}
                        />
                    ))}
                </div>
            </div>
            <div>
                <Typography placeholder="" variant="h6" className="mt-2">
                    {t("TYPESTYLES")}
                </Typography>
                <Select placeholder="" variant="static">
                    {allTypeStyles?.length > 0 ? (
                        allTypeStyles?.map((typeStyle: any) => (
                            <Option
                                key={typeStyle.id}
                                value={typeStyle.id}
                                onClick={() => {
                                    setTypeStyles([...typeStyles, typeStyle.enumValue]);
                                }}
                            >
                                {typeStyle.frLabel}
                            </Option>
                        ))
                    ) : (
                        <Option value="">{t("NO_DATA")}</Option>
                    )}
                </Select>
                <div className="flex gap-2 mt-2 mb-4 flex-wrap">
                    {typeStyles.map((type, index) => (
                        <Chip
                            key={type}
                            color="orange"
                            size="lg"
                            value={type}
                            onClose={() => {
                                const newTypeStyle = [...typeStyles];
                                newTypeStyle.splice(index, 1);
                                setCategories(newTypeStyle);
                            }}
                        />
                    ))}
                </div>
            </div>
            <Button onClick={handleSave} placeholder="">
                {t("SAVE")}
            </Button>
        </div>
    );
}
