import { Button, Input, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import AppSettingsAPI from "../../../services/API/clients/AppSettingsAPI";

export default function HomePicture({
    homePicture,
    setHomePicture,
    fetchData,
}: Readonly<{ homePicture: string; setHomePicture: Function; fetchData: () => Promise<void> }>) {
    const { t } = useTranslation();

    const handleSave = async () => {
        if (homePicture === "") {
            toast.error(t("HOME_PICTURE_must_be_filled"));
            return;
        }
        const datas = {
            value: homePicture,
        };
        const response = await AppSettingsAPI.modifAppSetting("HOME_PICTURE", datas);
        if (response.status === 200) {
            toast.success(t("HOME_PICTURE_updated"));
            await fetchData();
        } else {
            toast.error(t("HOME_PICTURE_error"));
        }
    };

    return (
        <div>
            <Typography placeholder="" variant="h5">
                {t("HOME_PICTURE")}
            </Typography>
            <Typography placeholder="" variant="small">
                {t("HOME_PICTURE_d")}
            </Typography>
            <div>
                <Input
                    crossOrigin=""
                    variant="static"
                    placeholder=""
                    type="text"
                    value={homePicture ?? 0}
                    onChange={(e) => {
                        setHomePicture(e.target.value);
                    }}
                />
            </div>
            <Button onClick={handleSave} placeholder="" className="mt-4">
                {t("SAVE")}
            </Button>
        </div>
    );
}
