import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import ReactCompareImage from "react-compare-image";
import { useTranslation } from "react-i18next";
import ModalWrapper from "./ModalWrapper";
import { Daum } from "../Interface/PictureResearch";

export default function ModalVisuOriginalPath({ open, setOpen, picture }: Readonly<{ open: boolean; setOpen: () => void; picture: Daum }>) {
    const { t } = useTranslation();

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center mb-4">
                    {t("picture")}
                </Dialog.Title>
                <FontAwesomeIcon
                    icon="x"
                    onClick={() => {
                        setOpen()
                    }}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer transition-all"
                />

                <div className="w-[400px] relative">
                    {picture?.originalPath && picture?.publicPath ? (
                        <>
                            <ReactCompareImage leftImage={picture?.publicPath} rightImage={picture?.minPath} />
                            <div className="absolute top-2 left-2 text-white" style={{ textShadow: "0px 0px 5px #000" }}>
                                {t("public")}
                            </div>
                            <div className="absolute top-2 right-2 text-white" style={{ textShadow: "0px 0px 5px #000" }}>
                                {t("original")}
                            </div>
                        </>
                    ) : (
                        <img src={picture?.minPath} alt="compare" />
                    )}
                </div>

                <div className="flex justify-center gap-4 mt-6">
                    <button
                        type="button"
                        className="rounded-full text-white bg-indian-500 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => setOpen()}
                    >
                        {t("close")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
