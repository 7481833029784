export const Headers = [
    {
        id: "picture",
        col: "picture.publicPath"
    },
    {
        id: "ID",
        col: "id"
    },
    {
        id: "Validations",
        col: "validations"
    }
]