import axios, { AxiosInstance, AxiosResponse } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { Storage } from "../storage";
import { toast } from "react-toastify";
import { refreshAuthLogic } from "./RefreshLogic";
import FileDownload from "js-file-download";

export enum CLIENT_TYPE {
    EZOOM,
    NO_INTERCEPTOR,
}

const EZOOM = process.env.REACT_APP_API;

const ezoomClient = axios.create({ baseURL: EZOOM });
const noInterceptor = axios.create({ baseURL: EZOOM });

interface APIResponse {
    status: number;
    body: any;
}

createAuthRefreshInterceptor(ezoomClient, refreshAuthLogic(`${EZOOM}/refresh-token`));

export default class APIBase {
    private client: AxiosInstance;
    private auth: boolean;

    constructor(type: CLIENT_TYPE) {
        this.auth = type !== CLIENT_TYPE.NO_INTERCEPTOR;
        switch (type) {
            case CLIENT_TYPE.EZOOM:
                this.client = ezoomClient;
                this.auth = true;
                break;
            case CLIENT_TYPE.NO_INTERCEPTOR:
                this.client = noInterceptor;
                this.auth = false;
                break;
        }
    }

    private async tryRequest(req: Promise<AxiosResponse<any, any>>): Promise<APIResponse> {
        try {
            const result = await req;
            return {
                status: result.status,
                body: result.data,
            };
        } catch (e: any) {
            if (this.isRefreshTokenError(e)) {
                window.location.href = "/";
                return { status: 401, body: e };
            } else {
                if (!e.response) {
                    toast.error("Une erreur est survenue, veuillez réessayer plus tard");
                    return {
                        status: e.response.status,
                        body: {},
                    };
                }
                return {
                    status: e.response.status,
                    body: e.response.data,
                };
            }
        }
    }

    private isRefreshTokenError(e: any) {
        if (e.status !== 401) return false;
        const path = e.request?.responseURL;
        if (!path) return false;
        const pathName = new URL(path).pathname;
        return pathName === "/refresh-token";
    }

    private async getAuthHeader(data?: string) {
        if (data) return { Authorization: `Bearer ${data}` };
        if (!this.auth) return {};
        const token = Storage.getToken();
        if (!token) return {};
        return { Authorization: `Bearer ${token}` };
    }

    async get(path: string) {
        const headers: any = await this.getAuthHeader();
        headers["Accept"] = "application/json";
        return this.tryRequest(this.client.get(path, { headers }));
    }

    async getTotp(path: string, dataTokens: { token: string, refresh_token: string, id: string }) {
        const headers: any = await this.getAuthHeader(dataTokens.token)
        headers["Content-Type"] = "application/json";
        headers["Accept"] = "application/json";
        return this.tryRequest(this.client.get(path, { headers }));
    }

    async getAndDownload(path: string, asName: string) {
        const headers: any = await this.getAuthHeader();
        headers["Accept"] = "application/json";
        const content = await this.tryRequest(this.client.get(path, { headers, responseType: "blob" }));
        if (content.status === 200) FileDownload(content.body, asName);
        return content;
    }

    async getArgs(path: string, data: any) {
        const headers: any = await this.getAuthHeader();
        headers["Accept"] = "application/json";
        return this.tryRequest(this.client.get(path, { headers: headers, data: data }));
    }

    async delete(path: string) {
        const headers: any = await this.getAuthHeader();
        headers["Accept"] = "application/json";
        return this.tryRequest(this.client.delete(path, { headers }));
    }

    async post(path: string, data: any) {
        const headers: any = await this.getAuthHeader();
        headers["Content-Type"] = "application/json";
        headers["Accept"] = "application/json";
        return this.tryRequest(this.client.post(path, data, { headers }));
    }

    async patch(path: string, data: any) {
        const headers: any = await this.getAuthHeader();
        headers["Content-Type"] = "application/json";
        headers["Accept"] = "application/json";
        return this.tryRequest(this.client.patch(path, data, { headers }));
    }

    async put(path: string, data: any) {
        const headers: any = await this.getAuthHeader();
        headers["Content-Type"] = "application/json";
        headers["Accept"] = "application/json";
        return this.tryRequest(this.client.put(path, data, { headers }));
    }
    
    async putTotp(path: string, data: any, dataTokens: { token: string, refresh_token: string, id: string }) {
        const headers: any = await this.getAuthHeader(dataTokens.token)
        headers["Content-Type"] = "application/json";
        headers["Accept"] = "application/json";
        return this.tryRequest(this.client.put(path, data, { headers }));
    }

    async postPicture(path: string, data: any) {
        const headers: any = await this.getAuthHeader();
        headers["Content-Type"] = "multipart/form-data";
        headers["Accept"] = "application/json";
        return this.tryRequest(this.client.post(path, data, { headers }));
    }

    async putPicture(path: string, data: any) {
        const headers: any = await this.getAuthHeader();
        headers["Content-Type"] = "multipart/form-data";
        headers["Accept"] = "application/json";
        return this.tryRequest(this.client.put(path, data, { headers }));
    }
}
