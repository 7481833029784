import React from "react";
import { IconButton, List, ListItem, Popover, PopoverContent, PopoverHandler, Typography } from "@material-tailwind/react";

import Pagination from "./Pagination";

import DefaultPictureEzoom from "../../assets/img/default-profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PicturesAPI from "../../services/API/clients/PicturesAPI";
import ModalRefusePicture from "../Modals/ModalRefusePicture";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Daum } from "../Interface/PictureResearch";
import ModalVisuOriginalPath from "../Modals/ModalVisuOriginalPathSearch";
import moment from "moment";

interface Props {
    header: { col: string; id: string }[];
    data: Daum[];
    itemsPerPage: { current: number; last: number; totalItems: number; parPage: number };
    callPictures: Function;
}

const TablePicturesResearch: React.FC<Props> = ({ header, data, itemsPerPage, callPictures }) => {
    const { t } = useTranslation();
    const [constantData, setConstantData] = React.useState<boolean[]>([]);
    const [openRefuse, setOpenRefuse] = React.useState<boolean>(false);
    const [currentId, setCurrentId] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleRefuse = async (isDelete: string, motifReasons: string[], message: string) => {
        if (loading) return;
        let datas;
        if (isDelete === "true") datas = { action: "delete" };
        else
            datas = {
                action: "refuse",
                reasons: motifReasons,
                message: message,
            };
        setLoading(true);
        const response = await PicturesAPI.validatePicture(currentId, datas);
        if (response.status === 200) {
            setLoading(false);
            setCurrentId("");
            callPictures(itemsPerPage.current);
        } else {
            setLoading(false);
            toast.error("Error");
        }
    };

    const DropDownReturning = ({ elements }: { elements: { key: string, value: string }[] }) => {
        return (
            <div className="flex items-center justify-center">
                <Popover>
                    <PopoverHandler>
                        <IconButton placeholder="" color="orange">
                            <FontAwesomeIcon icon="bars" />
                        </IconButton>
                    </PopoverHandler>
                    <PopoverContent placeholder="" className="max-h-[300px] bg-white overflow-y-auto z-30 px-1">
                        <List placeholder="">
                            {elements.map((element) => (
                                <ListItem key={element.key} placeholder="">
                                    {element.key} : {element.value}
                                </ListItem>
                            ))}
                        </List>
                    </PopoverContent>
                </Popover>
            </div>
        );
    }

    const writtenDocumentsOnClick = async (id: string) => {
        const response = await PicturesAPI.getWrittenDocuments(id)
        if (response.status === 200) {
            window.open(response.body.path, "_blank")
        }
    }

    const DropDownWrittenDocumentsReturning = ({ elements }: { elements: { id: string, name: string, mime: string }[] }) => {
        return (
            <div className="flex items-center justify-center">
                <Popover>
                    <PopoverHandler>
                        <IconButton placeholder="" color="orange">
                            <FontAwesomeIcon icon="bars" />
                        </IconButton>
                    </PopoverHandler>
                    <PopoverContent placeholder="" className="max-h-[300px] bg-white overflow-y-auto z-30 px-1">
                        <List placeholder="">
                            {elements.map((element) => (
                                <ListItem key={element.id} placeholder="" onClick={() => writtenDocumentsOnClick(element.id)}>
                                    {element.name}
                                </ListItem>
                            ))}
                        </List>
                    </PopoverContent>
                </Popover>
            </div>
        );
    }

    const DateReturning = ({ element, column }: { element: Daum; column: { id: string; col: string } }) => {
        let content = element[column.col as keyof typeof element] ?? "/";
        if (typeof content === "object") {
            content = JSON.stringify(content);
        }

        return (
            <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                {content.toString() === "/" ? content.toString() : moment(content.toString()).format("DD.MM.YYYY HH:mm")}
            </Typography>
        );
    }

    const ImageReturning = ({ keys, element }: { keys: number; element: Daum }) => {
        return (
            <button
                onClick={() => {
                    const oldConstantData = constantData;
                    oldConstantData[keys] = !oldConstantData[keys];
                    setConstantData([...oldConstantData]);
                }}
            >
                <div className="flex items-center overflow-hidden rounded-md" style={{ width: "75px", height: "75px" }}>
                    <img alt="placeholder" className="rounded-md h-full w-full object-cover object-center" src={element?.publicPath ?? DefaultPictureEzoom} />
                </div>
            </button>
        );
    };

    const TypoReturning = ({ element, column }: { element: Daum; column: { id: string; col: string } }) => {
        let content = element[column.col as keyof typeof element] ?? "/";
        if (typeof content === "object") {
            content = JSON.stringify(content);
        }

        return (
            <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                {content}
            </Typography>
        );
    };

    const TypoGallery = ({ element }: { element: Daum }) => {
        return (
            <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                {element?.galleries[0]?.parent?.title ?? "/"}
            </Typography>
        );
    };

    const WatermarkReturning = ({ element }: { element: string }) => {
        let value = ""
        if (element === "bl")
            value = "Bas gauche";
        else if (element === "br")
            value = "Bas droite";
        else if (element === "full")
            value = "Plein";
        else
            value = "none";
        return (
            <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                {t(value)}
            </Typography>
        );
    }

    const KeywordsReturning = ({ element }: { element: Daum }) => {
        if (element.keywords === undefined || element.keywords.length === 0)
            return (
                <Typography
                placeholder=""
                variant="small"
                color="blue-gray"
                className={"text-gray-500 whitespace-nowrap"}
                >/</Typography>
            )

        const keywords: {
            index: number;
            keyword: {
                frLabel: string;
                enLabel: string;
            };
        }[] = element.keywords ?? [];

        if (keywords.length === 0)
            return (
                <Typography
                placeholder=""
                variant="small"
                color="blue-gray"
                className={"text-gray-500 whitespace-nowrap"}
                >/</Typography>
            )


        const frLabels = keywords.map((keyword) => keyword.keyword?.frLabel ?? "/");

        return (
            <Typography
            placeholder=""
            variant="small"
            color="blue-gray"
            className={"text-gray-500 whitespace-nowrap"}
            >
                {frLabels.join(", ") || "/"}
            </Typography>
        );
    };

    return (
        <div className="table-container">
            <ModalRefusePicture open={openRefuse} setOpen={() => setOpenRefuse(false)} handleRefuse={handleRefuse} />
            <div className="w-full overflow-x-auto max-h-[52vh]">
                <table className="w-full min-w-[640px] table-auto overflow-x-scroll overflow-y-auto">
                    <thead>
                        <tr>
                            <th className="border-b border-blue-gray-50 py-3 px-5 text-center"></th>
                            {header?.map((column: { col: string; id: string }, index: number) => (
                                <th key={column.col} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                    <Typography
                                        placeholder=""
                                        variant="small"
                                        className={`text-[11px] font-bold uppercase text-blue-gray-400 whitespace-nowrap ${
                                            index === header.length - 1 ? "text-center" : "text-left"
                                        }`}
                                    >
                                        {t(column.id)}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((element, key: number) => {
                            const className = `py-1 px-5 ${key === data.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                            const handleOpenVisuOrga = () => {
                                const oldConstantData = constantData;
                                oldConstantData[key] = !oldConstantData[key];
                                setConstantData([...oldConstantData]);
                            };

                            return (
                                <>
                                    <ModalVisuOriginalPath open={constantData?.[key] ?? false} setOpen={handleOpenVisuOrga} picture={element} />
                                    <tr key={element.id} className={`example-element-row`}>
                                        <td className={`py-5 border-b flex flex-row`} style={{ textAlign: "center" }}>
                                            <IconButton
                                                color="red"
                                                placeholder=""
                                                onClick={() => {
                                                    setCurrentId(element.id);
                                                    setOpenRefuse(true);
                                                }}
                                            >
                                                <FontAwesomeIcon icon="trash" />
                                            </IconButton>
                                        </td>
                                        {header?.map((column: { id: string; col: string }) => {
                                            return (
                                                <td key={column.id} className={className}>
                                                    {column.id === "picture" && <ImageReturning keys={key} element={element} />}
                                                    {column.id === "exifData" && <DropDownReturning elements={element.exifData} />}
                                                    {column.id === "writtenDocuments" && <DropDownWrittenDocumentsReturning elements={element.writtenDocuments} />}
                                                    {column.id === "owner" && <a href={`/user/${element?.owner?.id}`}><IconButton placeholder="" color="orange"><FontAwesomeIcon icon="user" /></IconButton></a>}
                                                    {column.id === "galleries" && <TypoGallery element={element} />}
                                                    {column.id === "keywords" && <KeywordsReturning element={element} />}
                                                    {(column.id === "uploadedAt" || column.id === "publishedAt") && <DateReturning element={element} column={column} />}
                                                    {(column.id === "category") && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{element.category?.frLabel}</Typography>}
                                                    {(column.id === "typeStyle") && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{element.category?.frLabel}</Typography>}
                                                    {(column.id === "watermark") && <WatermarkReturning element={(element[column.col as keyof typeof element] ?? "none").toString()} />}
                                                    {(column.id === "license") && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{t(element.license)}</Typography>}
                                                    {(column.id === "nsfw" || column.id === "identifiablePeople" || column.id === "identifiableProperties" || column.id === "writtenContract" || column.id === "watermarkUptoDate" || column.id === "canBeSold" || column.id === "canBeBought") && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{element[column.col as keyof typeof element] ? <FontAwesomeIcon icon="check" /> : <FontAwesomeIcon icon="x" />}</Typography>}
                                                    {column.id !== "keywords" && column.id !== "writtenDocuments" && column.id !== "galleries" && column.id !== "owner" && column.id !== "picture" && column.id !== "exifData" && column.id !== "uploadedAt" && column.id !== "publishedAt" && column.id !== "category" && column.id !== "typeStyle" && column.id !== "watermark" && column.id !== "license" && <TypoReturning element={element} column={column} />}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <Pagination
                handlePageChange={callPictures}
                itemsPerPage={itemsPerPage.parPage}
                currentPage={itemsPerPage.current}
                totalItems={itemsPerPage.totalItems}
                startIndex={itemsPerPage.current * itemsPerPage.parPage - itemsPerPage.parPage}
                endIndex={
                    itemsPerPage.current * itemsPerPage.parPage - 1 > itemsPerPage.totalItems
                        ? itemsPerPage.totalItems - 1
                        : itemsPerPage.current * itemsPerPage.parPage - 1
                }
            />
        </div>
    );
};

export default TablePicturesResearch;
