import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import ModalWrapper from "./ModalWrapper";
import { useState } from "react";
import { Checkbox, Option, Select, Textarea, Typography } from "@material-tailwind/react";
import { toast } from "react-toastify";

const datasCheckbox = [
    {
        id: 1,
        label: "edit",
    },
    {
        id: 2,
        label: "upload_documents",
    },
    {
        id: 3,
        label: "upload_original",
    }
]

export default function ModalRefusePicture({ open, setOpen, handleRefuse }: Readonly<{ open: boolean; setOpen: () => void; handleRefuse: Function }>) {
    const { t } = useTranslation();
    const [isDelete, setIsDelete] = useState<string>("false");
    const [reasons, setReasons] = useState<string[]>([]);
    const [message, setMessage] = useState<string>("");

    const handleSubmit = () => {
        if (reasons.length === 0 && isDelete === "false") {
            toast.error(t("selectReason"));
            return;
        }
        handleRefuse(isDelete, reasons, message);
        setOpen();
    };

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center mb-4">
                    {t("picturerefuse")}
                </Dialog.Title>
                <FontAwesomeIcon
                    icon="x"
                    onClick={() => {
                        return "cancel";
                    }}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer transition-all"
                />

                <div className="w-[400px] relative flex flex-col gap-3">
                    <Select
                        label={t("typeRefuse")}
                        placeholder=""
                        value={isDelete}
                        onChange={(e: string | undefined) => e && setIsDelete(e)}
                        color="orange"
                        className="bg-white"
                    >
                        <Option value="false">{t("refuse")}</Option>
                        <Option value="true">{t("delete")}</Option>
                    </Select>
                    {isDelete === "false" && (
                        <div className="flex flex-col">
                            <Typography placeholder="" className="text-sm text-gray-600">
                                {t("actionByUser")}
                            </Typography>
                            {datasCheckbox.map((data) => (
                                <Checkbox
                                    key={data.id}
                                    crossOrigin=""
                                    label={t(data.label)}
                                    checked={reasons.includes(data.label)}
                                    color="orange"
                                    className="h-4 w-4"
                                    labelProps={{ className: "text-sm" }}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setReasons([...reasons, data.label]);
                                        } else {
                                            setReasons(reasons.filter((reason) => reason !== data.label));
                                        }
                                    }}
                                />
                            ))}
                        </div>
                    )}
                    <Textarea
                        label={t("comment")}
                        placeholder=""
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        color="orange"
                        className="bg-white"
                    />
                </div>

                <div className="flex justify-center gap-4 mt-6">
                    <button
                        type="button"
                        className="rounded-full text-black border bg-white px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => handleSubmit()}
                    >
                        {t("confirm")}
                    </button>
                    <button
                        type="button"
                        className="rounded-full text-white bg-indian-500 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => setOpen()}
                    >
                        {t("close")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
