import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Option, Select, Typography } from "@material-tailwind/react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import UsersAPI from "../../../services/API/clients/UsersAPI";
import { toast } from "react-toastify";

export default function UserActions({ id, reloadDatas, currentPage, enabled } : Readonly<{ id: string, reloadDatas: (page: number) => void, currentPage: number, enabled: boolean }>) {
    const { t } = useTranslation();

    // const disabledUser = async (onClose: Function, valueToReport: string) => {
    //     if (valueToReport === "DISABLE") {
    //         const response = await UsersAPI.changeStatus(id, {});
    //         if (response.status === 200 || response.status === 204) {
    //             toast.success(t("successDisable"))
    //             reloadDatas(currentPage)
    //         }
    //         onClose()
    //     }
    // }


    const disabledUser = async (onClose: Function, valueToReport: string) => {
        if (valueToReport === "DISABLE") {
            if (!id) return;
            const datas = {
                enabled: !enabled,
            }
            const response = await UsersAPI.changeStatus(id, datas);
            if (response.status === 200 || response.status === 201) {
                if (enabled) 
                    toast.success(t("successEnable"));
                else
                    toast.success(t("successDisable"));
                reloadDatas(currentPage);
                onClose();
            } else {
                toast.error(t("errorDisable"));
            }
        } else if (valueToReport === "DELETE") {
            if (!id) return;
            const response = await UsersAPI.deleteUser(id);
            if (response.status === 200 || response.status === 204) {
                toast.success(t("successDelete"));
                reloadDatas(currentPage);
                onClose();
            } else {
                toast.error(t("errorDelete"));
            }
        }
    };

    const onClickActions = () => {
        let valueToReport: string = "";
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="py-4 bg-white rounded-lg shadow-lg border-2 border-gray-300 w-screen lg:w-[60vw] relative">
                        <FontAwesomeIcon icon="x" className="absolute top-2 right-2 text-gray-500 cursor-pointer" onClick={onClose} />
                        <Typography placeholder="" className="text-center w-full text-2xl">{t("userActions")}</Typography>
                        <div className="w-full flex mt-4 justify-center">
                            <div className="w-full flex flex-col px-2 items-center justify-center">
                                <div className="w-[300px] mx-auto">
                                    <Select placeholder="" color="orange" label={t("userAction")} className="w-full" onChange={(e) => e && (valueToReport = e)}>
                                        <Option value="DISABLE" color="orange">
                                            {enabled ? t("DISABLE") : t("ENABLE")}
                                        </Option>
                                        <Option value="DELETE" color="orange">
                                            {t("DELETE")}
                                        </Option>
                                    </Select>
                                </div>
                                <div className="flex gap-4 items-center justify-center mt-2">
                                    <button
                                        className="rounded-full border border-orange-500 hover:border-orange-200 bg-orange-500 hover:bg-orange-200 text-white px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans my-2"
                                        onClick={() => disabledUser(onClose, valueToReport)}
                                    >
                                        {t("confirm")}
                                    </button>
                                    <button
                                        className="rounded-full border bg-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans my-2"
                                        onClick={() => onClose()}
                                    >
                                        {t("cancel")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            },
        });
    }

    return (
        <IconButton placeholder="" color="orange" size="sm" onClick={() => onClickActions()}>
            <FontAwesomeIcon icon="user" />
        </IconButton>
    )
}